import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/category_text.css'
import '../../static/css/contents_list.css'
import '../../static/css/react-calendar-heatmap.css'
import '../../static/css/side.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'
import '../../static/css/font_awesome.css'
import '../../static/css/floating.css'
import React from 'react'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { ContentsList } from '../components/ArticleList'
import { Breadcrumb } from '../components/Breadcrumb'
import { Floating } from '../components/Floating'
import Seo from '../components/Seo'
import { url } from '../config/domain'
import { useStaticQuery, graphql } from 'gatsby'
import { getResizeImgUrl } from '../util/format'
import { category } from '../config/category'
import loadable from '@loadable/component'

const Script = loadable(() => import('../components/Adsense/Script'))

export default function CategoryArticleList({ location }: any): JSX.Element {
  const data = useStaticQuery(
    graphql`
      query {
        allArticle(sort: { order: DESC, fields: updatedAt___seconds }) {
          edges {
            node {
              category
              description
              article
              id
              thumbnail
              title
              updatedAt {
                seconds
              }
            }
          }
        }
      }
    `
  )
  const locatePath = location.pathname.split('/')
  // urlからcategory名を取得
  const categoryName = locatePath[1]

  let categoryData: {
    value: string
    name: string
    description: string
  } = {
    value: '',
    name: '',
    description: '',
  }

  // urlが子カテゴリーの場合に、parentに値が格納される。パンくずをいい感じに生成するため。
  let parent: undefined | any = undefined
  category.map((cate: any) => {
    if (cate.children.length > 0) {
      cate.children.map((child: any) => {
        if (child.name === categoryName) {
          categoryData = child
          parent = cate
        }
      })
    }
    if (cate.name === categoryName) {
      categoryData = cate
    }
  })

  const articleList = data.allArticle.edges.filter(
    (o: {
      node: {
        category: string
        description: string
        id: number
        thumbnail: string
        title: string
        updatedAt: {
          seconds: number
        }
      }
    }) => {
      if (parent && o.node.title.includes(categoryData.value)) {
        return true
      } else {
        return o.node.category === categoryName
      }
    }
  )

  const breadcrumb = parent
    ? [
        {
          link: '/',
          text: 'KURORO BLOG',
        },
        {
          link: '/' + (parent ? parent.name : '') + '/',
          text: parent ? parent.value : '',
        },
        {
          link: '/' + (categoryData ? categoryData.name : '') + '/',
          text: categoryData ? categoryData.value : '',
        },
      ]
    : [
        {
          link: '/',
          text: 'KURORO BLOG',
        },
        {
          link: '/' + (categoryData ? categoryData.name : '') + '/',
          text: categoryData ? categoryData.value : '',
        },
      ]

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={categoryData ? categoryData.value + 'に関する記事一覧' : '' + 'に関する記事一覧'}
          description={
            '「' +
            (categoryData ? categoryData.value : '') +
            '」に関する記事一覧ページです。現在' +
            articleList.length +
            '件の' +
            (categoryData ? categoryData.value : '') +
            'に関する記事を掲載しております。' +
            (categoryData ? categoryData.value : '') +
            'についての基礎知識から実践的な使い方まで明瞭に解説しております。'
          }
          url={url + (categoryData ? categoryData.name : '') + '/'}
          imgList={[
            getResizeImgUrl(articleList[0].node.thumbnail, '120x120'),
            getResizeImgUrl(articleList[0].node.thumbnail, '350x232'),
          ]}
          noIndex={false}
          contentType="article"
        />
        <Header location={locatePath} />
        <section className="categoryText__section">
          <h1>{categoryData?.value}</h1>
          <p>
            {'「' +
              categoryData?.value +
              '」に関する記事一覧ページです。現在' +
              articleList.length +
              '件の' +
              categoryData?.value +
              'に関する記事を掲載しております。' +
              categoryData?.description}
          </p>
        </section>
        <ContentsList
          articles={articleList}
          location={locatePath}
          title={categoryData ? categoryData.value + 'に関する記事一覧' : '' + 'に関する記事一覧'}
        />
        <Breadcrumb breadcrumb={breadcrumb} />
        <Footer />
      </div>
      <Floating />
      <Script />
    </div>
  )
}
